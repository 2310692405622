import { ShoppingCartItem } from '@obeta/models/lib/models/ShoppingCart'
import { useCallback } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import {
  GetProductPriceForReplacementProductQueryVariables,
  GetReplacementProductsForProductsQuery,
  GetProductPriceForReplacementProductQuery,
  GetReplacementProductsForProductsQueryVariables,
} from '@obeta/schema'
import { ProductAggregate } from '@obeta/models/lib/models/Article/Shop/Product'
import { priceStockSearchQuery } from '../queries/priceStockSearchQuery'
import { useWarehouseContext } from '../stores/useWarehouseContext'
import { useUserDataV2 } from './useUserDataV2'

type UseReplacementProducts = () => {
  loadReplacementProductsAsShoppingCartItems: (
    sapId: string,
    cartId: string
  ) => Promise<ShoppingCartItem[] | undefined>
}

const REPLACEMENT_PRODUCTS_FOR_PRODUCTS = gql`
  query getReplacementProductsForProducts($sapIds: [String!]) {
    getProducts(sapIds: $sapIds) {
      replacementProducts {
        id
        sapId
        dehaId
        type
        isCurrentlyNotAvailable
        unit
        minimumAmount
        isCutProduct
        isSendable
        title
        oxomiId
        supplierId
        dehaProductGroupId
        isPvArticle
        isForSale
        isDiscontinued
        replacementArticleSapId
        imageData {
          images {
            large
          }
        }
        images {
          url
          width
        }
        supplierImageData {
          large
        }
        priceDimension
      }
    }
  }
`

const PRICE_QUERY = gql`
  query getProductPriceForReplacementProduct($sapId: String!) {
    getProductDetailPrices(sapId: $sapId) {
      metalNeAddition
      netPrice
      tecSelect
      strikeThroughPrice
      listPrice
      currency
    }
  }
`

export const useReplacementProducts: UseReplacementProducts = () => {
  const apolloClient = useApolloClient()
  const { user } = useUserDataV2()
  const { warehouseId } = useWarehouseContext()

  const loadReplacementProductsAsShoppingCartItems = useCallback(
    async (sapId, cartId) => {
      const response = await apolloClient.query<
        GetReplacementProductsForProductsQuery,
        GetReplacementProductsForProductsQueryVariables
      >({
        query: REPLACEMENT_PRODUCTS_FOR_PRODUCTS,
        variables: {
          sapIds: [sapId],
        },
      })

      if (!response?.data?.getProducts?.[0]?.replacementProducts) return

      // Convert products to items of type ShoppingCartItem
      const shoppingCartItems: ShoppingCartItem[] =
        response?.data?.getProducts?.[0]?.replacementProducts?.map((product) => {
          const productAsShoppingCartItem: ShoppingCartItem = {
            id: product.id,
            cartId,
            sapId: product.sapId,
            amount: 0,
            product: product as ProductAggregate,
          }

          return productAsShoppingCartItem
        })

      // Fetch prices for the first two shoppingCartItems, since only those are shown
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      const promises: Promise<any>[] = []
      shoppingCartItems.slice(0, 2).forEach((item) => {
        const promise = apolloClient.query<
          GetProductPriceForReplacementProductQuery,
          GetProductPriceForReplacementProductQueryVariables
        >({
          query: PRICE_QUERY,
          variables: {
            sapId: item.sapId,
          },
        })
        promises.push(promise)
      })
      const prices = await Promise.all(promises)

      const replacementProductsSapIds = shoppingCartItems.slice(0, 2).map((item) => item.sapId)

      const stocks = await apolloClient.query({
        query: priceStockSearchQuery,
        variables: {
          sapIds: replacementProductsSapIds,
          warehouseIds: [user?.settings?.defaultStoreId, warehouseId].filter(Boolean),
        },
      })

      // Merge prices into shoppingCartItems
      shoppingCartItems.slice(0, 2).forEach((item, index) => {
        if (prices && prices[index]) {
          item.prices = prices[index].data.getProductDetailPrices
        }
        if (stocks && stocks.data.getProducts[index].sapId === item.sapId) {
          item.product.stock = stocks.data.getProducts[index].stock
        }
      })

      return shoppingCartItems
    },
    [apolloClient, user?.settings?.defaultStoreId, warehouseId]
  )

  return {
    loadReplacementProductsAsShoppingCartItems,
  }
}
