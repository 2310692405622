import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ShoppingCartItem } from '@obeta/models/lib/models'
import { useReplacementProducts } from './useReplacementProducts'

type ReplacementVariant = 'DISCONTINUED_ITEM_WITHOUT_STOCK' | 'DISCONTINUED_ITEM_WITH_PARTIAL_STOCK'

type UseReplacementDialogOptions = {
  itemOfChoice: ShoppingCartItem
}

export type ReplacementAmountItem = { sapId: string; amount: number }
type UseReplacementDialogResult = {
  title: string
  description: string
  disableItemOfChoice: boolean
  itemOfChoiceAmount: number
  replacementAmounts: Array<ReplacementAmountItem>
  changeReplacementItemAmount: (
    sapId: string,
    amount: number,
    replacementItems: ShoppingCartItem[]
  ) => void
  replacementItems: ShoppingCartItem[]
  setReplacementItems: (replacementItems: ShoppingCartItem[]) => void
  setShouldFetchReplacementItems: (shouldFetchReplacementItems: boolean) => void
}

type UseReplacementDialog = (options: UseReplacementDialogOptions) => UseReplacementDialogResult

const getReplacementVariant = (
  itemOfChoice: ShoppingCartItem,
  replacementItems: ShoppingCartItem[]
): ReplacementVariant => {
  if (itemOfChoice.product.isDiscontinued && getTotalAmountInStock(itemOfChoice) > 0) {
    return 'DISCONTINUED_ITEM_WITH_PARTIAL_STOCK'
  }

  return 'DISCONTINUED_ITEM_WITHOUT_STOCK'
}

export const getTotalAmountInStock = (shoppingCartItem: ShoppingCartItem): number => {
  let totalAmountStocked = 0
  if (!shoppingCartItem) {
    return totalAmountStocked
  }
  shoppingCartItem.product.stock?.forEach((stock) => {
    totalAmountStocked = stock.amount + totalAmountStocked
  })
  return totalAmountStocked
}
export const isEnoughAmountInStock = (
  desiredAmount: number,
  totalAmountStocked: number
): boolean => {
  return desiredAmount <= totalAmountStocked
}
const getInitialReplacementAmounts = (
  itemOfChoice: ShoppingCartItem,
  replacementItems: ShoppingCartItem[],
  itemOfChoiceAmount = 0
): ReplacementAmountItem[] => {
  // at the moment only the first element is set
  const firstItem = replacementItems[0]
  let firstReplacementItemAmount = itemOfChoice.amount - itemOfChoiceAmount

  const totalAmountInStock = getTotalAmountInStock(firstItem)
  if (
    firstItem.product.isDiscontinued &&
    !isEnoughAmountInStock(itemOfChoice.amount, totalAmountInStock)
  ) {
    firstReplacementItemAmount = totalAmountInStock
  }

  return [{ sapId: firstItem.sapId, amount: firstReplacementItemAmount }]
}

export const useReplacementDialog: UseReplacementDialog = ({ itemOfChoice }) => {
  // TODO: amount for each item needs to be calculated if there is more then one (COD-15256), remove eslint-disable when in use
  const [replacementItems, setReplacementItems] = useState<ShoppingCartItem[]>([])

  const [replacementAmounts, setReplacementAmounts] = useState<ReplacementAmountItem[]>([])
  const [itemOfChoiceAmount, setItemOfChoiceAmount] = useState(0)
  const [replacementVariant, setReplacementVariant] = useState<ReplacementVariant>(
    'DISCONTINUED_ITEM_WITHOUT_STOCK'
  )
  const [withoutStockVariant, setWithoutStockVariant] = useState<boolean>(false)
  const [shouldFetchReplacementItems, setShouldFetchReplacementItems] = useState<boolean>(true)

  const { t } = useTranslation()

  const { loadReplacementProductsAsShoppingCartItems } = useReplacementProducts()
  const loadReplacementProducts = async () => {
    if (shouldFetchReplacementItems === false) {
      return
    }
    const result = await loadReplacementProductsAsShoppingCartItems(
      itemOfChoice.sapId,
      itemOfChoice.cartId as string
    )

    if (!result) {
      setReplacementItems([])
      setReplacementAmounts([])
      return
    }

    setReplacementItems(result)
    setReplacementAmounts(getInitialReplacementAmounts(itemOfChoice, result, itemOfChoiceAmount))

    const replacementVariant = result && getReplacementVariant(itemOfChoice, result)
    setReplacementVariant(replacementVariant)
    const withoutStockVariant = replacementVariant === 'DISCONTINUED_ITEM_WITHOUT_STOCK'
    setWithoutStockVariant(withoutStockVariant)
  }

  useEffect(() => {
    if (!shouldFetchReplacementItems) setShouldFetchReplacementItems(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOfChoice.product.sapId])

  useEffect(() => {
    loadReplacementProducts()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replacementItems.length, itemOfChoice.product.sapId])

  useEffect(() => {
    if (replacementItems.length > 0 && replacementAmounts.length === 0) {
      setReplacementAmounts(
        getInitialReplacementAmounts(itemOfChoice, replacementItems, itemOfChoiceAmount)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replacementItems, itemOfChoice.product.sapId])

  useEffect(() => {
    let partialStock = 0
    //item of choice the amount counter of which is not disabled is always isDiscontinued true
    if (itemOfChoice.product.isDiscontinued) {
      const maxItemOfChoiceInStock = getTotalAmountInStock(itemOfChoice)
      if (!isEnoughAmountInStock(itemOfChoice.amount, maxItemOfChoiceInStock)) {
        partialStock = maxItemOfChoiceInStock
      } else {
        partialStock = itemOfChoice.amount
      }
    }
    setItemOfChoiceAmount(withoutStockVariant ? 0 : partialStock)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withoutStockVariant, itemOfChoice.product.sapId])

  const changeReplacementItemAmount = useCallback(
    (sapId: string, amount: number, replacementItems) => {
      const newReplacementAmounts = [...replacementAmounts]
      const index = replacementAmounts.findIndex((item) => item.sapId === sapId)
      if (index >= 0 && index < newReplacementAmounts.length) {
        const totalAmountInStock = getTotalAmountInStock(replacementItems[index])
        if (
          replacementItems[index].product.isDiscontinued &&
          !isEnoughAmountInStock(amount, totalAmountInStock)
        ) {
          amount = totalAmountInStock
        }
        newReplacementAmounts[index] = { ...replacementAmounts[index], amount }
      }

      setReplacementAmounts(newReplacementAmounts)
    },
    [replacementAmounts]
  )

  return {
    title: t(`REPLACEMENT.DIALOG.${replacementVariant}.TITLE`),
    description: t(`REPLACEMENT.DIALOG.${replacementVariant}.DESCRIPTION`, {
      count: itemOfChoice.amount,
      unit: itemOfChoice.product.unit,
    }),
    disableItemOfChoice: withoutStockVariant,
    itemOfChoiceAmount,
    replacementAmounts,
    changeReplacementItemAmount,
    replacementItems,
    setReplacementItems,
    setShouldFetchReplacementItems,
  }
}
