import React, {
  FC,
  forwardRef,
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { ReactComponent as AddShoppingCartIcon } from '@obeta/assets/icon/add_shopping_cart.svg'
import { ReactComponent as CheckCircleIcon } from '@obeta/assets/icon/designsystem/check_circle.svg'
import { ReactComponent as HighlightOffIcon } from '@obeta/assets/icon/designsystem/highlight_off.svg'
import clsx from 'clsx'
import { CustomButton, PrimaryButton } from '../custom-button/CustomButton'
import { SplitButton } from '../split-button/SplitButton'
import styles from './AnimatedCartButton.module.scss'
import { AnimatedButtonStateContext, EButtonState } from './AnimatedCartButtonContext'

interface AnimatedCartButtonProps {
  cartsLength?: number
  title?: string
  onArrowDownClicked?: (event: React.MouseEvent<HTMLElement>) => void
  onClick: () => void
  size?: 'small' | 'large'
  withoutTitle?: boolean
  disabled?: boolean
  className?: string
  buttonState?: EButtonState
}

export const AnimatedCartButton = forwardRef<HTMLDivElement, AnimatedCartButtonProps>(
  (props, ref) => {
    const {
      title = '',
      cartsLength = 1,
      onArrowDownClicked,
      onClick,
      size,
      withoutTitle,
      disabled,
      buttonState: _propButtonState,
      ...rest
    } = props

    const { animationState } = useContext(AnimatedButtonStateContext)

    const [currentButtonState, setCurrentState] = useState(EButtonState.Initial)

    useEffect(() => {
      setCurrentState(_propButtonState ?? animationState)
    }, [_propButtonState, animationState])

    const [width, setWidth] = useState<number>()
    const localRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      const element = ref
        ? (ref as MutableRefObject<HTMLDivElement | null>).current
        : localRef.current

      if (element) {
        setWidth(element.offsetWidth)
      }
    }, [ref, title, cartsLength])

    useEffect(() => {
      if (
        currentButtonState === EButtonState.Error ||
        currentButtonState === EButtonState.Success
      ) {
        const timer = setTimeout(() => {
          setCurrentState(EButtonState.Initial)
        }, 1000)

        return () => clearTimeout(timer)
      }
    }, [currentButtonState])

    const handleClick = () => {
      if (currentButtonState === EButtonState.Initial) onClick()
    }

    return currentButtonState === EButtonState.Initial ||
      currentButtonState === EButtonState.Loading ? (
      <div className={styles.animatedButton}>
        <AnimatedLoaderBackground loading={currentButtonState === EButtonState.Loading} />
        {cartsLength > 1 ? (
          <SplitButton
            disabled={disabled}
            ref={ref || localRef}
            title={title}
            onArrowDownClicked={onArrowDownClicked}
            onClick={handleClick}
            size={size}
            withoutTitle={withoutTitle}
            loading={false}
            {...rest}
          />
        ) : (
          <PrimaryButton
            leftIcon={<AddShoppingCartIcon />}
            size={size}
            onClick={handleClick}
            disabled={disabled}
            usedInSplitButton
          >
            {!withoutTitle && title}
          </PrimaryButton>
        )}
      </div>
    ) : (
      <NotificationButton buttonState={currentButtonState} size={size} width={width} />
    )
  }
)

const AnimatedLoaderBackground: FC<{ loading: boolean }> = ({ loading }) => (
  <svg className={styles.svg} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
    <rect
      className={clsx(styles.outline, { [styles.loadingAnimation]: loading })}
      width="100%"
      height="100%"
      shapeRendering="crispEdges"
      pathLength="100"
      rx="6"
      ry="6"
    />
  </svg>
)

interface NotificationButtonProps {
  buttonState: EButtonState.Success | EButtonState.Error
  size?: 'small' | 'large'
  width?: number
}

const NotificationButton: FC<NotificationButtonProps> = ({ buttonState, size, width }) => (
  <div
    className={clsx(styles.notificationButton, {
      [styles.error]: buttonState === EButtonState.Error,
    })}
  >
    <CustomButton
      size={size}
      buttonType="primary"
      leftIcon={buttonState === EButtonState.Success ? <CheckCircleIcon /> : <HighlightOffIcon />}
      usedInSplitButton
      style={{ width: width }}
    />
  </div>
)
