import { trackClick } from './tracking'
import { IdsFormFields } from '@obeta/schema'

export const submitIdsPostForm = (fields: IdsFormFields, url: string, target: string) => {
  const idsForm = document.createElement('form')
  idsForm.method = 'POST'
  idsForm.action = url
  idsForm.target = target
  let input = document.createElement('input')
  const track = []

  Object.entries(fields).forEach((field) => {
    input = document.createElement('input')
    input.type = 'hidden'
    input.name = field[0]
    input.value = field[1]
    idsForm.appendChild(input)
    track[input.name] = input.value
  })
  document.body.appendChild(idsForm)
  trackClick('ids-form-submit', Object.assign({}, track))
  idsForm.submit()
}
