import React, { useState, useMemo } from 'react'

export enum EButtonState {
  Initial = 'initial',
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
}

interface Props {
  setAnimationState: React.Dispatch<React.SetStateAction<EButtonState>>
  animationState: EButtonState
}

const defaultState: Props = {
  animationState: EButtonState.Initial,
  setAnimationState: () => {
    //
  },
}

export const AnimatedButtonStateContext = React.createContext<Props>(defaultState)

export const useAnimatedButtonState = () => {
  const [current, setCurrent] = useState(EButtonState.Initial)
  const val = useMemo(
    () => ({
      setAnimationState: setCurrent,
      animationState: current,
    }),
    [current]
  )
  return val
}
