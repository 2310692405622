import { getSessionContext } from '@obeta/utils/lib/session-context'

export const useIsIdsTransferBackPossible = () => {
  const sessionContext = getSessionContext()
  return (
    sessionContext?.type === 'ids' &&
    typeof sessionContext?.hookUrl === 'string' &&
    sessionContext?.hookUrl.length > 0
  )
}
