import { buildNextRouterState } from '../history/buildNextRouterState'
import { isParsedUrlQueryInput, isUrlObject } from '../url'
import { withMd5Id } from '../genMd5Id'
import { ShopRoutes } from '../variables'

export const buildSearchUrlWithDehaIds = (
  dehaIds: string[],
  customTitle?: string,
  productDehaIdsFilterLabel?: string
): string | undefined => {
  const nextRouterState = buildNextRouterState(ShopRoutes.Search, {
    searchParams: withMd5Id({
      id: '',
      productDehaIds: dehaIds,
      customTitle,
      productDehaIdsFilterLabel,
    }),
  })
  if (isUrlObject(nextRouterState) && isParsedUrlQueryInput(nextRouterState.query)) {
    return `${nextRouterState.pathname}?state=${nextRouterState.query.state}`
  }
}
