import React from 'react'
import styles from './LoadingIndicator.module.scss'
import clsx from 'clsx'

interface LoadingIndicatorProps {
  isOfferInquirySubmitButton?: boolean
}

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = (props) => {
  const { isOfferInquirySubmitButton = false } = props
  return (
    <div
      className={
        isOfferInquirySubmitButton
          ? clsx(styles.indicator, styles.indicatorOfferInquiry)
          : styles.indicator
      }
      style={isOfferInquirySubmitButton ? { backgroundColor: '#3F3F3E' } : undefined}
    >
      <span
        className={styles.circle1}
        style={isOfferInquirySubmitButton ? { backgroundColor: '#FFFFFF' } : undefined}
      />
      <span
        className={styles.circle2}
        style={isOfferInquirySubmitButton ? { backgroundColor: '#FFFFFF' } : undefined}
      />
      <span
        className={styles.circle3}
        style={isOfferInquirySubmitButton ? { backgroundColor: '#FFFFFF' } : undefined}
      />
    </div>
  )
}
